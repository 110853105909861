<template>
	<div
		class="event_pop overflow-y-auto"
	>
		<template
			v-if="idx == 1"
		>
			<button
				class="event_close"
				@click="toBack"
			>
				<img :src="require('@/assets/image/event/event_close.png')" alt="닫기버튼">
			</button>
			<img :src="require('@/assets/image/event/event1.png')" alt="아트카르텔대항전 상세내용">
			<div class="flex">
				<button
					@click="toIntent"
				>
					<img :src="require('@/assets/image/event/event2.png')" alt="GooglePlay">
				</button>
				<button
					@click="toIos"
				>
					<img :src="require('@/assets/image/event/event3.png')" alt="AppStore">
				</button>
			</div>

			<img :src="require('@/assets/image/event/event4.png')" alt="아트카르텔대항전 상세내용">

			<button>
				<img :src="require('@/assets/image/event/event5.png')" alt="문의사항">
			</button>
			<div class="flex">
				<button
					@click="toRequest"
				>
					<img :src="require('@/assets/image/event/event6.png')" alt="카르텔개설신청">
				</button>
				<button
					@click="toHome"
				>
					<img :src="require('@/assets/image/event/event7.png')" alt="메타마피아홈">
				</button>
			</div>
			<img :src="require('@/assets/image/event/event9.png')" alt="메타마피아">
		</template>
		<div
			v-else
		>
			<div
				class="box-event"
				style=""
			>
				<iframe
					id="frame"
					:src="item_event.event_url"
					class="frame-event"
					frameborder="0"
					border="0"
				></iframe>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'EventDetail'
	, props: ['user']
	, data: function(){
		return {
			program: {
				not_header: this.user.member_number ? false : true
				, not_footer: true
				, type: this.user.member_number ? '' : 'event'
			}
			, item_event: {
				event_url: ''
			}
			, idx: ''
			, item_event_info: {

			}
		}
	}
	, methods: {
		toBack: function(){
			this.$emit('goBack')
		}
		, toHome: function(){
			document.location.href = '/'
		}
		, toRequest: function(){
			document.location.href = '/Mypage/CartelRequest'
		}
		, toIntent: function(){
			window.open('https://play.google.com/store/search?q=%EB%A9%94%ED%83%80%EB%A7%88%ED%94%BC%EC%95%84&c=apps', 'intent')
		}
		, toIos: function(){
			window.open("https://apps.apple.com/kr/app/%EB%A9%94%ED%83%80%EB%A7%88%ED%94%BC%EC%95%84", "intent");
		}
		, getEvent: function(){
			let event_list = this.$codes.event_list

			let domain = process.env.VUE_APP_DOMAIN
			let domain_m = process.env.VUE_APP_DOMAIN_M
			let domain_stg = process.env.VUE_APP_DOMAIN_STG
			let domain_stgm = process.env.VUE_APP_DOMAIN_STG_M
			let domain_dev = process.env.VUE_APP_DOMAIN_DEV
			let domain_devm = process.env.VUE_APP_DOMAIN_DEV_M
			let location = window.location.href

			let items = ''

			// 운영계 API 호출
			if(location.indexOf(domain) > -1 || location.indexOf(domain_m) > -1) {
				items = event_list['live']

				// 검증계 API 호출
			}else if(location.indexOf(domain_stgm) > -1 || location.indexOf(domain_stg) > -1){
				items = event_list['stg']

				// 개발계 API 호출
			}else if(location.indexOf(domain_dev) > -1 || location.indexOf(domain_devm) > -1){
				items = event_list['dev']
				// 로컬 API 호출
			}else{
				items = event_list['dev']
			}

			try{

				this.item_event = items[this.idx - 1]
				this.getEventInfo()
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}

		, postEvent: async function(vote_data){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_out_event_vote
					, data: {
						member_number: this.user.member_number
						, event_number: this.item_event.code
						, vote_participation_list: vote_data
					}
					, type: true
				})

				if (result.success) {
					await this.getEventInfo()
					this.$bus.$emit('notify', { type: 'success', message: '투표가 완료되었습니다'})
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, onEvent: function(e){
			if(typeof e.data === "string"){
				let d = JSON.parse(e.data)

				if(d.type == 'close'){

					let d = localStorage.getItem('system_event')
					if(d){
						this.$emit('goBack')
					}else{
						if(confirm("이벤트 참여를 종료하시겠습니까?")){
							this.$emit('goBack')
						}
					}
				}else if(d.type == 'vote'){
					if(this.user.member_number){

						let vote = d.data
						let vote_data = []
						for(let i = 0; i < vote.length; i++){

							if(Number(vote[i]) > this.item_event_info.vote_event_info.vote_event_optiop_list.length){
								alert('잘못된 투표 요청입니다')
							}else{
								vote_data.push({
									vote_optiop_number: this.item_event_info.vote_event_info.vote_event_optiop_list[Number(vote[i]) - 1].optiop_number
									, vote_choice_value: this.item_event_info.vote_event_info.vote_event_optiop_list[Number(vote[i]) - 1].recognition_value
								})
							}
						}
						if(vote_data.length > 0){
							this.postEvent(vote_data)
							this.is_event = false
						}
					}else{
						alert("로그인 후 이벤트참여가능합니다")
						this.$bus.$emit('to', { name: 'login'})
					}
				}
			}
		}
		, onusEvent: function(){
			window.removeEventListener('message', this.onEvent)
			window.addEventListener('message', this.onEvent)
		}
		, getEventInfo: async function(){
			if(this.item_event.code) {
				try {
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_out_event
						, data: {
							member_number: this.user.member_number
							, event_number: this.item_event.code
						}
						, type: true
					})

					if (result.success) {
						this.item_event_info = result.data.event_info
						setTimeout( () => {

							let frame = document.getElementById('frame')

							frame.contentWindow.postMessage({
								key: 'event'
								, data: this.item_event_info
							}, '*')
						}, 1000)
					} else {
						throw result.message
					}
				} catch (e) {
					console.log(e)
					this.$bus.$emit('notify', {type: 'error', message: e})
				} finally {
					this.$bus.$emit('on', false)
				}
			}
		}

		// 2023.05.15 탑버튼 기능 추가 - 카단
		, toTop: function(){
			window.scrollTo({
				top:0,
				behavior: 'smooth',
			});
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.idx = this.$route.params.idx
		this.getEvent()
		if(this.idx == 4){
			this.onusEvent()
		}
		localStorage.setItem('system_event', this.$date.getToday('-'))
	}
}
</script>
<style>

.box-event {
	position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color:  white; z-index: 9999999; overflow: hidden
}

.box-event::-webkit-scrollbar {
	width: 1px; color: black;
}
.frame-event {
	width: 100%; height: 100%; border: none; overflow: hidden; margin: 0; padding: 0
}

.frame-event::-webkit-scrollbar {
	width: 1px; color: black;
}
</style>
